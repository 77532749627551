<template>

<app-wrapper class="search">

	<div class="search-close" v-on:click="$emit('close')"><i class="fa fa-times"></i></div>

	<app-wrapper :content="true">

		<div class="search-input">
		
			<input ref="input" type="text" v-model="search" placeholder="Search..." />

			<i class="fa-solid fa-spinner fa-spin" v-if="is.searching"></i>

		</div>

		<div class="search-item" v-for="(result, index) in results" :key="index">

			<router-link :to="{path: result.url}">{{ result.name }}</router-link>

			<small v-if="result.date">{{ result.date | formatDate('Do MMMM YYYY') }}</small>

			<p>{{ result.description }}</p>

		</div>

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				searching: false
			},
			search: '',
			results: []
		}

	},

	created: function() {

		this.$nextTick(function() {

			this.$refs.input.focus()

		}.bind(this))

	},

	watch: {

		search: function() {

			this.load()

		}

	},

	methods: {

		load: function() {

			if (this.search.length > 2) {

				this.is.searching = true

				this.$api.get('search', {
					search: this.search
				}).then(function(json) {

					this.results = json.results

					this.is.searching = false

				}.bind(this))

			} else {

				this.is.searching = false

				this.results = []

			}
			
		}

	}

}

</script>

<style scoped>

.search {
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #112A45;
	padding: 64px 0px;
	z-index: 6;
	overflow: auto;
}

.is-mobile .search {
	padding: 40px 0px;
}

.search-close {
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	color: #fff;
	font-size: 32px;
	cursor: pointer;
	position: absolute;
	right: 20px;
	top: 20px;
}

.is-mobile .search-close {
	right: 4px;
	top: 4px;
}

.search-input {
	position: relative;
	margin: 0px auto;
	width: 100%;
	display: block;
	max-width: 600px;
	margin-bottom: 64px;
}

.is-mobile .search-input {
	margin-bottom: 0px;
}

.search-input input {
	border-bottom: 1px solid #BBD9CD;
	color: #fff;
	height: 64px;
	font-weight: 500;
	width: 100%;
	font-size: 32px;
	padding: 0px 60px 0px 20px;
}

.is-mobile .search-input input {
	height: 48px;
	font-size: 24px;
	padding: 0px 40px 0px 0px;
}

.search-input input::placeholder {
	font-weight: 300;
	color: #fff;
}

.search-input i {
	position: absolute;
	right: 20px;
	top: 19px;
	color: #BBD9CD;
	font-size: 24px;
}

.is-mobile .search-input i {
	right: 0px;
	top: 20px;
	font-size: 16px;
}

.search-item {
	border-bottom: 1px solid #BBD9CD;
	padding: 20px 0px;
}

.search-item:last-child {
	border-bottom: 0px;
}

.search-item a {
	color: #fff;
	font-size: 24px;
	line-height: 32px;
	text-align: left;
}

.is-mobile .search-item a {
	font-size: 20px;
	line-height: 24px;
}

.is-desktop .search-item a:hover { 
	text-decoration: underline;
}

.search-item small {
	color: #BBD9CD;
	font-size: 14px;
	font-weight: 400;
	margin-top: 4px;
	display: block;
}

.search-item p {
	color: #fff;
	font-size: 16px;
	line-height: 24px;
	margin-top: 20px;
}

.is-mobile .search-item p {
	font-size: 14px;
	line-height: 20px;
}

</style>
